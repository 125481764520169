.info-lottery {
  &__user {
    .item {
      margin-bottom: 3px;
      p {
        font-weight: bold;
        margin-bottom: 0;
        span {
          color: red;
        }
      }
    }
  }
  &__lottery {
    h3 {
      text-align: center;
    }
    .lottery-info {
      &__item {
        font-weight: bold;
        margin-bottom: 0;
        span {
          color: red;
        }
      }
    }
    .lottery-number {
      margin-top: 10px;
      display: grid;
      grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
      column-gap: 2px;
      row-gap: 10px;
      &__select {
        span {
          border: 2px solid #dd2c00;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: auto;
          font-size: 14px;
        }
      }
    }
  }
}
