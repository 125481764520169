@import './utils';

html {
  font-size: 62.5%;
}

body {
  font-size: 1.4rem;
}

.container {
  width: 80%;
  max-width: 128rem;
  margin: 0 auto;
}

.flex {
  display: flex;
  &.justify-center {
    justify-content: center;
  }
  &.justify-between {
    justify-content: space-between;
  }
  &.align-center {
    align-items: center;
  }
}

.p {
  &1 {
  }
  &2 {
  }
  &3 {
  }

  &t1 {
    padding-top: 1em;
  }
  &t2 {
    padding-top: 2em;
  }
  &t3 {
    padding-top: 3em;
  }
}

.m {
  &t1 {
    margin-top: 1em;
  }
  &t2 {
    margin-top: 2em;
  }
  &t3 {
    margin-top: 3em;
  }
}
