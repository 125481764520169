.login-screen {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .form-card-container {
    width: 400px;
    padding: 2.4rem;
    border: 1px solid #ececec;
    border-radius: 4px;
    box-shadow: 0 0 30px rgba(black, 0.015);
  }
  .btn-containers {
    display: flex;
    justify-content: center;
  }
}
