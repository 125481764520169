.keyword-row {
  .search {
    div.ant-space-item {
      width: 100%;
    }
  }
}
.badge-add {
  background-color: #f0ffe3;
  text-align: center;
  width: 50%;
  border-radius: 3px;
  color: #247702;
  border: 1px solid #6b9e55;
}
.badge-withdraw {
  background-color: #c9d9f8;
  text-align: center;
  width: 50%;
  border-radius: 3px;
  color: #243fc6;
  border: 1px solid #004eff;
}

.info-lottery {
  &__user {
    .item {
      margin-bottom: 3px;
      p {
        font-weight: bold;
        margin-bottom: 0;
        span {
          color: red;
        }
      }
    }
  }
  &__lottery {
    h3 {
      text-align: center;
    }
    .lottery-info {
      &__item {
        font-weight: bold;
        margin-bottom: 0;
        span {
          color: red;
        }
      }
    }
  }
}
