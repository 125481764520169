.search__row {
  .row-tabname {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .row-search {
    display: flex;
  }
}
