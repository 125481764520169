.ads-form-screen{
    .flex{
        h1{
            font-size: 30px;
        }
    }
    div.flex{
        .btn-add{
            background-color: SeaGreen; 
            color: white ;
            text-align: center ;
            border-radius: 5px;
        }
        .btn-update{
            background-color: LightSalmon; 
            color: white ;
            text-align: center ;
            border-radius: 5px;
        }
    }
}