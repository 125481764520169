.module{
    :global{
        ul{
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            margin: 0;
            list-style: none;
            gap: 4px;
            li{
                padding: 0;
                margin: 0;
                background-color: red;
                color: white;
                padding: 4px;
                border-radius: 50%;
                width: 2.4rem;
                height: 2.4rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .last-num{
            background-color: orange;
        }
    }   
}