.ant-layout-header.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    color: white;
    padding-right: 2.4rem;
    font-size: 1.25em;
    font-weight: bold;
  }
}
.site-layout-background {
  //   background: #fff;
}
